import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const AppBarSimple = () => {
	const navigate = useNavigate();
		
	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar color="default" elevation={1} position="fixed" >
				<Toolbar>
					<Typography onClick={()=>{navigate('/')}}  variant="span" component="div" sx={{ cursor:'pointer'}} color="primary">
						<b>GAME VISION PRO</b>
					</Typography>

					<Box sx={{ ml:2, flexGrow: 1, flexDirection: 'rows', display: {xs:'none', sm:'flex'}, alignItems:'center' }}>
					
						<Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={()=>{navigate('/')}} >
							Início
						</Button>

						<Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={()=>{navigate('/tutorial')}} >
							Tutorial
						</Button>

						<Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={()=>{navigate('/precos')}} >
							Preços
						</Button>
					</Box>

					<Box sx={{ flexGrow: 1, display: {sm:'none', xs:'flex'}, alignItems:'center' }}>

					</Box>
					<Button onClick={()=>{navigate('/login')}} variant="contained" size="small">
						Entrar
					</Button>
					
				</Toolbar>
			</AppBar>
		</Box>
	);
}

export default AppBarSimple;