import { get, ref } from "firebase/database";
import { database } from "./firebase";

export const getUserPermission = async (userId,setPermissionDate) => {    
    await get(ref(database,`/users/${userId}/expiration_date`) )
        .then((snapshot)=>{
            if (snapshot.exists()){
                setPermissionDate(snapshot.val());
            }
        }).catch((error)=>{
            console.log(error);
        })
}