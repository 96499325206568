import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Footer = () => {
	return(
		<Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', }}>
			<Container maxWidth='false' >
				<Typography variant="caption" sx={{ display: 'flex', justifyContent: 'center' }} align="center" color="text.secondary">© 2023-2024 Game Vision PRO - Todos os direitos reservados.</Typography>				
			</Container>
			
		</Box>
	);
};

export default Footer;