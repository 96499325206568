import { Box } from "@mui/material";
import Footer from "./Footer";
import AppBarSimple from "./AppBarSimple";

const Layout = ({children}) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', }}>
            <AppBarSimple />
            {children}
            <Footer />
        </Box>
    )
}

export default Layout;