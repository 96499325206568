import {useEffect, useState} from 'react';

import AppBarSimple from "../components/AppBarSimple";
import Footer from "../components/Footer";
import Loader from '../components/Loader';
import CustomLink from '../util/CustomLink';

import { useAuth } from "../context/AuthContext";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Google } from '@mui/icons-material';

import * as EmailValidator from 'email-validator';
import { useNavigate, useLocation } from "react-router-dom";

const Login = () => {
  
  const [state, setState] = useState({email: '', password:'', isEmailInvalid: false, isPasswordInvalid: false, isAuthError: false});
  const [error, setError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [emailError, setEmailError] = useState(null);      
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const redirectUrl = new URLSearchParams(location.search).get("redirect");
  const [loaded, setLoaded] = useState(false);
  const [recover, setRecover] = useState(false);

  const {user, signInWithEmail, signInWithGoogle} = useAuth();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
      
  const handleSubmit = async event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    setError(null);
    
    if (!validateEmail(email)) {
      setState({ ...state, isEmailInvalid: true });
      setEmailError("Email inválido.");
      return;
    }else if(!validatePassword(password)){
      setState({ ...state, isPasswordInvalid: true });
      setPasswordError("A senha deve ter no mínimo 6 caracteres.");
      return;
    }else{
      signInWithEmail(email, password, setError);
    };    
  };

  const handleGoogleLogin = async () => {
    signInWithGoogle();
  }
    
  const recheckEmail = (email) => {
    setError(null);
    if(state.isEmailInvalid){
      if(validateEmail(email)){
        setState({ ...state, isEmailInvalid: false });
      }
    }
  };
    
  const recheckPassword = (password) => {
    setError(null);
    if(state.isPasswordInvalid){
      if(validatePassword(password)){
        setState({ ...state, isPasswordInvalid: false });
      }
    }
  };
  
  const validateEmail = (email) => {
    return EmailValidator.validate(email);
  };
  const validatePassword = (password) => {
    return password.length<6 ? false : true;
  };
  
  useEffect(()=>{      
    if(user && !redirectUrl){
      navigate('/dashboard');
    }else if(user && redirectUrl){
      navigate(`/${redirectUrl}`);
    }else{
      setLoaded(true);
    }    
    return ()=>{};
  },[user,navigate,redirectUrl]);


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', }}>
			<AppBarSimple />
      {loaded ? (
        
          !recover ? (
            <Container component="main" maxWidth="xs" sx={{mb: 8}}>
              <CssBaseline />
              <Box sx={{marginTop: 12, display: 'flex',  flexDirection: 'column', alignItems: 'center',}} >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Entrar
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    color="secondary"
                    autoComplete="email"
                    //autoFocus
                    onChange={e => recheckEmail(e.target.value)}
                    error={state.isEmailInvalid}
                    helperText={state.isEmailInvalid ? emailError : " "}
                  />
                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    name="password"
                    label="Senha"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    color="secondary"
                    autoComplete="current-password"
                    onChange={e => recheckPassword(e.target.value)}
                    error={state.isPasswordInvalid}
                    helperText={state.isPasswordInvalid ? passwordError : " "}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="small"
                          >
                            {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Typography
                    onClick={()=>{setRecover(true)}}
                    style={{ cursor: 'pointer' }}
                  >
                    Esqueceu a senha?
                  </Typography>
                  
                  <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      sx={{ mt: 3, mb: 0 }}
                      
                  >
                      Entrar
                  </Button>

                  {error ? (
                      <Typography variant="caption" color="error">
                          {error}
                      </Typography>
                      ) : (
                      <Box height={24} />
                  )}

                  <Divider>ou</Divider>

                  <Button
                    fullWidth
                    variant="outlined"
                    size="large"
                    startIcon={<Google />}
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleGoogleLogin}
                    >
                    Entrar com Google
                  </Button>
                                    
                  <CustomLink href={redirectUrl ? '/criar-conta?redirect='+redirectUrl : '/criar-conta'}>{"Não possui conta? Inscreva-se já."}</CustomLink>
    
                </Box>
              </Box>
            </Container>
          ):(
            <RecuperarSenha setRecover={setRecover} />
          )
        
      ):(
        <Loader vh={80}/>
      )}
      <Footer />
    </Box>
  );
}

export default Login;

const RecuperarSenha = ({ setRecover }) => {
  const [state, setState] = useState({email: '', isEmailInvalid: false });
	const [emailError, setEmailError] = useState(null);
	const [success, setSuccess] = useState(false);	
  const {sendPasswordReset} = useAuth();
		
	const handleClose = (e) => {
		e.preventDefault();
    setRecover(false);
	}
	
	const handleSubmit = async event => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const email = data.get('email');
		
		if (!validateEmail(email)) {
			setState({ ...state, isEmailInvalid: true });
			setEmailError("Email inválido.");
			return;
		}else{
      sendPasswordReset(email);
      setSuccess(true);			
		};
		
	};
			
	const recheckEmail = (email) => {
		if(state.isEmailInvalid){
			if(validateEmail(email)){
				setState({ ...state, isEmailInvalid: false });
			}
		}
	};
		
	const validateEmail = (email) => {
		return EmailValidator.validate(email);
	};

  return(
    <Container component="main" maxWidth="xs" sx={{mb: 8}}>
      <CssBaseline />
      <Box sx={{marginTop: 12, display: 'flex',	flexDirection: 'column', alignItems: 'center',}} >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Esqueci a Senha
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="dense"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            color="secondary"
            autoComplete="email"
            autoFocus
            onChange={e => recheckEmail(e.target.value)}
            error={state.isEmailInvalid}
            helperText={state.isEmailInvalid ? emailError : " "}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            sx={{ mt: 3, mb: 0 }}
            
          >
            Recuperar Senha
          </Button>
          <Box height={24} />			
          
          
          <Typography
            onClick={()=>{setRecover(false)}}
            style={{ cursor: 'pointer' }}
          >
            Voltar para tela de Login.
          </Typography>
          
        </Box>
      </Box>
      <Dialog
				open={success}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				>
				<DialogTitle id="alert-dialog-title">
					{"Recuperação de Senha"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Enviamos um email para você redefinir sua senha. Verifique sua caixa de entrada. 
            <br/><br/>
            Você receberá o email apenas se você realmente possui uma conta ativa.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>
						Fazer Login
					</Button>
				</DialogActions>
			</Dialog>
    </Container>
  );

}