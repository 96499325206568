import { Check } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SubscribeDialog = ({openSubscribeDialog,setOpenSubscribeDialog}) =>{
    
    const navigate = useNavigate();
    
    return(
        <Dialog open={openSubscribeDialog} onClose={()=>{setOpenSubscribeDialog(false)}}>
            <DialogContent sx={{display:'flex',flexDirection:"column", alignItems:'center'}}>
                
                <DialogTitle>
                    Faça o Upgrade da sua Conta
                </DialogTitle>

                <DialogContentText>
                    Você atingiu o limite de análises desse plano.
                </DialogContentText>

                <DialogContentText>
                    Mude para o Plano Profissional e faça análises ilimitadas.
                </DialogContentText>

                <Box sx={{mt:3}}>
                    <Typography color="text.secondary" component="div" gutterBottom>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Check color="primary" />
                            <span>Todos os Painéis de Análise.</span>
                        </Box>
                    </Typography>
                    <Typography color="text.secondary" component="div" gutterBottom>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Check color="primary" />
                            <span>Análises Ilimitadas.</span>
                        </Box>
                    </Typography>
                    <Typography color="text.secondary" component="div" gutterBottom>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Check color="primary" />
                            <span>Exportação sem Marca d`água.</span>
                        </Box>
                    </Typography>
                </Box>
                <Button sx={{mt:4,mb:2}} color="inherit" variant="outlined" onClick={()=>{navigate('/precos')}}>
                    Conheça os Planos
                </Button>

            </DialogContent>
        </Dialog>
    );
}

export default SubscribeDialog;