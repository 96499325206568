import { Box, Divider } from "@mui/material";
import Pricing from "../components/Pricing";
import Footer from "../components/Footer";
import AppBarSimple from "../components/AppBarSimple";
import Layout from "../components/Layout";

const PricingPage = () => {
    return(
        <Layout>
            <Box sx={{mt:8}}>
                <Pricing />
            </Box>
        </Layout>
    );
}

export default PricingPage;