// Import the functions you need from the SDKs you need
import { getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAxVHOgNaUWLjaejxbFwDTMI5EE_dF6py0",
  authDomain: "game-vision-6f49e.firebaseapp.com",
  //authDomain: "gamevision.pro",
  databaseUrl: "https://game-vision-6f49e-default-rtdb.firebaseio.com",
  projectId: "game-vision-6f49e",
  storageBucket: "game-vision-6f49e.appspot.com",
  messagingSenderId: "293405237053",
  appId: "1:293405237053:web:df21199f52e31dfef0ef54",
  measurementId: "G-FQL5KRL7XY"
};

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
//const database = getDatabase(app);

export const firebaseApp = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
export const auth = getAuth(firebaseApp);
export const database = getDatabase(firebaseApp);
export const storage = getStorage(firebaseApp);
export const analytics = getAnalytics(firebaseApp);