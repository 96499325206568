import { Suspense, lazy } from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

//import Login from './Login';
import Login from './pages/Login';
import Dashboard from "./dashboard/Dashboard";

import { Routes, Route, BrowserRouter } from "react-router-dom";
import './lib/firebase/firebase';
import Loader from './components/Loader';
import Tutorial from './pages/Tutorial';
import LandingPage from './pages/LandingPage';
import PricingPage from './pages/PricingPage';
import { AuthProvider } from './context/AuthContext';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const Analisar = lazy(() => import('./analisar/Analisar'));
const Signup = lazy(() => import('./pages/Signup'));
const Assinar = lazy(() => import('./pages/assinatura/Assinar'));
const RedeemBonus = lazy(() => import('./pages/RedeemBonus'));
const Updates = lazy(() => import('./pages/Updates'));
const Support = lazy(() => import('./pages/Support'));

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />          
            <Route
              path="/analisar/:analiseUid"
              element={
                <Suspense fallback={<Loader vh={80}/>}>
                  <Analisar />
                </Suspense>
              }
            />
            <Route path="*" element={<LandingPage />} />
            <Route path="/dashboard" element={<Dashboard/>} />
            <Route path="/tutorial" element={<Tutorial/>} />
            <Route path="/precos" element={<PricingPage/>} />
            <Route
              path="/criar-conta"
              element={
                <Suspense fallback={<Loader vh={80}/>}>
                  <Signup />
                </Suspense>
              }
            />
            <Route
              path="/assinar"
              element={
                <Suspense fallback={<Loader vh={80}/>}>
                  <Assinar />
                </Suspense>
              }
            />
            <Route
              path="/redeemBonus/:bonusUid"
              element={
                <Suspense fallback={<Loader vh={80}/>}>
                  <RedeemBonus />
                </Suspense>
              }
            />
            <Route
              path="/updates"
              element={
                <Suspense fallback={<Loader vh={80}/>}>
                  <Updates />
                </Suspense>
              }
            />
            <Route
              path="/suporte"
              element={
                <Suspense fallback={<Loader vh={80}/>}>
                  <Support />
                </Suspense>
              }
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
