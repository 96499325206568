import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";

const CustomLink = ({ href, children }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
	navigate(href);
  };

  return (
    <Link href={href} color="text.primary" variant="body2" underline="none" onClick={handleClick}>
      {children}
    </Link>
  );
};

export default CustomLink;