import { Button, CircularProgress, Dialog, DialogContent, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { AccountCircle } from "@mui/icons-material";

const UserMenu = () => {
	const [anchorEl, setAnchorEl] = useState(null);
    const [openLoader, setOpenLoader] = useState(false);
    
    const navigate = useNavigate();
    const { user, loading, permission, signOut } = useAuth()

	const handleMenu = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

    const handleSubscription = async (e) => {
        e.preventDefault();
        setOpenLoader(true);
        handleClose();
        const { url } = await fetch('https://us-central1-game-vision-6f49e.cloudfunctions.net/endPoint/account', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: user.email
            }),
        }).then(r => r.json());
        
        console.log(url);
        
        if(url){
            window.location.href = url;
        }else{
            setOpenLoader(false);
        }
        
    }
    const handleSupport = (e) => {
        e.preventDefault();
        handleClose();
        navigate('/suporte');
    }
    
    return(
        <div>

            {user && !loading && (
                <div>

                    {!permission && (
                        <Button variant="outlined" size="small" onClick={()=>{navigate('/precos')}}>                        
                            Assine o PRO
                        </Button>
                    )}    

                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(e)=>{handleMenu(e);}}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>

                    <Menu
                        id="user-menu"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}            
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >	
                        <MenuItem onClick={handleSubscription}>Minha Conta</MenuItem>
                        <MenuItem onClick={handleSupport}>Suporte Técnico</MenuItem>
                        <Divider/>
                        <MenuItem onClick={()=>{signOut();handleClose();}}>Sair</MenuItem>
                    </Menu>

                </div>
            )}
        
            {!user && !loading && (
                <div>
                    <Button size="small" variant="contained">
                        Entrar
                    </Button>
                </div>
            )}

            <Dialog open={openLoader}>
                <DialogContent sx={{width:'40vw',height:'40vh', display:'flex', justifyContent:'center', alignItems:'center'}} >
                    <CircularProgress />
                </DialogContent>
            </Dialog>

        </div>
    )
}

export default UserMenu;