import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const GenericDialog = ({openDialog,setOpenDialog,title,message}) => {
    return(
        <Dialog open={openDialog} onClose={()=>{setOpenDialog(false)}}>
            
            <DialogTitle>
                {title}
            </DialogTitle>
            
            <DialogContent>
            
                <DialogContentText>
                    {message}
                </DialogContentText>

                <DialogActions>
                    <Button variant="outlined" onClick={()=>{setOpenDialog(false)}}>
                        Fechar
                    </Button>
                </DialogActions>

            </DialogContent>
        </Dialog>
    );
}

export default GenericDialog;