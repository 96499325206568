import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Typography, createFilterOptions } from '@mui/material';

import { MuiColorInput } from 'mui-color-input'
import { getDatabase, push, ref, set } from 'firebase/database';
import { Panels } from '../timeline/panels/Panels';
import { useAuth } from '../context/AuthContext';

export default function NewAnalysisDialog({newAnalysisDialogOpen, setNewAnalysisDialogOpen, userUid}) {
  const { permission } = useAuth();
  const [title, setTitle] = useState('');
  const [selectedPanel, setSelectedPanel] = useState('');
  const [teamNames, setTeamNames] = useState(['','']);
  const [colors, setColors] = useState(['#095FA3', '#C20000']);
  const [allPanels, setAllPanels] = useState([]);
  const [customPanelOptions, setCustomPanelOptions] = useState([]);
  const [customPanelLeftOptions, setCustomPanelLeftOptions] = useState([]);
  const [customPanelRightOptions, setCustomPanelRightOptions] = useState([]);

  const filter = createFilterOptions();
  
	const handleChangeColor = (index, color) => {
		setColors(prevColors => {
			const newColors = [...prevColors];
			newColors[index] = color;
			return newColors;
		});
	};

  const handleClose = () => {
    setNewAnalysisDialogOpen(false);
  };

  const handleConfirm = () => {
    //console.log('handleConfirm');
    //criar no banco de dados
    const dbAnalisesRef = ref(getDatabase(), `/users/${userUid}/analises`);
    const newAnalisesRef = push(dbAnalisesRef);
    const analiseUid = newAnalisesRef._path.pieces_[3];
    const currentDate = new Date().toISOString();

    set(newAnalisesRef, {
      capa: "/images/capa-template.jpg",
      data: currentDate,
      lastUpdate: currentDate,
      title: title,
      panel: Panels[selectedPanel].panel,
      uid: analiseUid,
    });
    
    let currentPanel = Panels[selectedPanel];
    currentPanel.title = title;

    if(selectedPanel !== 1){
      //Apenas Painel de edição de vídeo [1] não precisa os painéis laterais
      currentPanel.leftActions[0].title = teamNames[0];
      currentPanel.leftActions[0].colors[0] = colors[0];
      currentPanel.leftActions[0].colors[1] = colors[1];
      currentPanel.rightActions[0].title = teamNames[1];
      currentPanel.rightActions[0].colors[0] = colors[1];
      currentPanel.rightActions[0].colors[1] = colors[0];
    }
    
    //Painel Customizado precisamos gerar os grupo de ações
    if(selectedPanel === 3){
      //Grupo de Ações da Esquerda
      customPanelLeftOptions.map((group,index)=>{
        currentPanel.leftActions[0].actions.push(
          {
            title: group.inputValue,
            button:"Adicionar",
            hasOptions:true,
            hasSlider: true,
            multipleOptions:true,
            addOptions: true,
            customBullets: false,
            analytics: 'donut',
            options:[], 
            data:[]
          }
        )
      });

      //Grupo de Ações da Direita
      customPanelRightOptions.map((group,index)=>{
        currentPanel.rightActions[0].actions.push(
          {
            title: group.inputValue,
            button:"Adicionar",
            hasOptions:true,
            hasSlider: true,
            multipleOptions:true,
            addOptions: true,
            customBullets: false,
            analytics: 'donut',
            options:[], 
            data:[]
          }
        )
      });

    }
    
    const dbDadosRef = ref(getDatabase(), `/users/${userUid}/analises_dados/${analiseUid}`);
    set(dbDadosRef, currentPanel);
    
    //abrir página da análise
    window.location.href = `/analisar/${analiseUid}`;

  }

  const handleOptionsChange = (e,newValue,side) => {

    const validNewValue = newValue.filter(newOption => 
      typeof newOption === 'object' && newOption !== null && !Array.isArray(newOption));

    setCustomPanelOptions(prevOptions => {    
      const newOptionsToAdd = validNewValue.filter(newOption => 
        !prevOptions.some(option => option.title === newOption.title));      
      return [...prevOptions, ...newOptionsToAdd];
    });

    if(side==="left"){
      setCustomPanelLeftOptions(validNewValue);
    }else{
      setCustomPanelRightOptions(validNewValue);
    }
  }

  useEffect(()=>{
    setTitle('');
    setSelectedPanel('');
    setTeamNames(['','']);
    setColors(['#095FA3', '#C20000']);
    setAllPanels(Panels);
  },[newAnalysisDialogOpen]);

  return(
    <>
      <Dialog open={newAnalysisDialogOpen} onClose={handleClose}>
        <DialogTitle>Nova Análise</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha as informações para iniciar uma nova análise. 
          </DialogContentText>
          <TextField
            value={title}
            onChange={(e)=>{setTitle(e.target.value)}}
            margin="normal"
            id="title"
            label="Título da Análise"
            fullWidth
          />
          {/*<TextField
            value={descricao}
            onChange={(e)=>{setDescricao(e.target.value)}}
            margin="normal"
            id="descricao"
            label="Descricao"
            fullWidth
            multiline
          />*/}
          <FormControl fullWidth sx={{mt:2,mb:1}}>
            <InputLabel>Painel</InputLabel>
            <Select
              id="panel"
              value={selectedPanel}
              onChange={(e)=>{setSelectedPanel(e.target.value);}}
              label="Painel"
            >
              {allPanels.map((panel,index)=>(
                <MenuItem key={index} value={index} disabled={!permission ? panel.permission : false }>
                  {panel.panel}
                  {!permission && panel.permission && (
                    <Chip sx={{ml:1}} label="PRO" color="primary" size="small" variant="outlined" />
                  )
                }</MenuItem>
              ))}

            </Select>
          </FormControl>
          { allPanels[selectedPanel]?.configurationCase === 1 && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    value={teamNames[0]}
                    onChange={(e)=>{setTeamNames([e.target.value,teamNames[1]])}}
                    margin="normal"
                    label="Painel Esquerdo"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MuiColorInput
                    fullWidth
                    margin="normal"
                    value={colors[0]}
                    onChange={(e)=>{handleChangeColor(0,e);}}
                    format="hex" 
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    value={teamNames[1]}
                    onChange={(e)=>{setTeamNames([teamNames[0],e.target.value])}}
                    margin="normal"
                    label="Painel Direito"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MuiColorInput
                    fullWidth
                    margin="normal"
                    value={colors[1]}
                    onChange={(e)=>{handleChangeColor(1,e);}}
                    format="hex" 
                  />
                </Grid>
              </Grid>
            </>
          )}
          { allPanels[selectedPanel]?.configurationCase === 2 && (
            <>
              <Grid container spacing={2} sx={{mt:2}}>
                <Grid item xs={12}>
                  <Typography variant="h5">Painel Esquerdo</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    value={teamNames[0]}
                    onChange={(e)=>{setTeamNames([e.target.value,teamNames[1]])}}                    
                    label="Título do Painel Esquerdo"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MuiColorInput
                    fullWidth
                    value={colors[0]}
                    onChange={(e)=>{handleChangeColor(0,e);}}
                    format="hex" 
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple={true}
                    //value={textFields[groupIndex][actionIndex]}                  
                    freeSolo
                    clearOnBlur={true}
                    options={customPanelOptions}
                    getOptionLabel={(option) => {
                      let isExisting = customPanelOptions.some((optionlabel) => optionlabel.inputValue === option.inputValue);
                      if (isExisting) {
                        return option.inputValue;
                      }else{
                        return option.title;
                      }
                    }}
                    filterOptions={(options, params) =>{
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      let isExisting = options.some((option) => inputValue === option.inputValue);
                      if (inputValue !== '' && !isExisting ) {
                        filtered.push({
                          inputValue,
                          title: `Adicionar "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    onChange={(e,value)=>{
                      handleOptionsChange(e,value,"left");
                    }}
                    value={customPanelLeftOptions}
                    renderInput={(params) => <TextField label="Grupos de Ações" {...params} />}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{mt:2}}>
                <Grid item xs={12}>
                  <Typography variant="h5">Painel Direito</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    value={teamNames[1]}
                    onChange={(e)=>{setTeamNames([teamNames[0],e.target.value])}}                    
                    label="Título do Painel Direito"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MuiColorInput
                    fullWidth
                    value={colors[1]}
                    onChange={(e)=>{handleChangeColor(1,e);}}
                    format="hex" 
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple={true}
                    //value={textFields[groupIndex][actionIndex]}                  
                    freeSolo
                    clearOnBlur={true}
                    options={customPanelOptions}
                    getOptionLabel={(option) => {
                      let isExisting = customPanelOptions.some((optionlabel) => optionlabel.inputValue === option.inputValue);
                      if (isExisting) {
                        return option.inputValue;
                      }else{
                        return option.title;
                      }
                    }}
                    filterOptions={(options, params) =>{
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      let isExisting = options.some((option) => inputValue === option.inputValue);
                      if (inputValue !== '' && !isExisting ) {
                        filtered.push({
                          inputValue,
                          title: `Adicionar "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    onChange={(e,value)=>{
                      handleOptionsChange(e,value,"right");
                    }}       
                    value={customPanelRightOptions}
                    renderInput={(params) => <TextField label="Grupos de Ações" {...params} />}
                  />
                </Grid>
              </Grid>
            </>
          )}
          

        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>Cancelar</Button>
          <Button 
            variant="contained" 
            onClick={handleConfirm}
            disabled={
              (title === '') || 
              (selectedPanel === null) || 
              (selectedPanel !== 1 && ( teamNames[0] === '' || teamNames[1] === '') )               
            }
          >Confirmar</Button>
        </DialogActions>
      </Dialog>
    </>
  );

}