export const convertBrToISO = (brDate) => {
    const [day, month, year] = brDate.split('/');
    const isoDate = new Date(`${year}-${month}-${day}`).toISOString();
    return isoDate;
};

export const convertISOToBr = (isoDate) => {
    const date = new Date(isoDate);
    //const options = { day: 'numeric', month: 'short'};
    const options = { day: 'numeric', month: 'short', year: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };        
    const formatoBrasileiro = new Intl.DateTimeFormat('pt-BR', options).format(date);
    return formatoBrasileiro;
};

export const dataAmigavel = (dataISO) => {
    const dataAtual = new Date();
    const dataRecebida = new Date(dataISO);

    const diferencaMilissegundos = dataAtual - dataRecebida;
    const diferencaSegundos = Math.floor(diferencaMilissegundos / 1000);
    const diferencaMinutos = Math.floor(diferencaSegundos / 60);
    const diferencaHoras = Math.floor(diferencaMinutos / 60);
    const diferencaDias = Math.floor(diferencaHoras / 24);

    if (diferencaMinutos < 1) {
        return `Última alteração há menos de um minuto`;
    } else if (diferencaHoras < 1) {
        return `Última alteração há ${diferencaMinutos} minutos`;
    } else if (diferencaDias < 1) {
        return `Última alteração há ${diferencaHoras} horas`;
    } else if (diferencaDias === 1) {
        return `Última alteração há ${diferencaDias} dia`;
    }else if (diferencaDias < 7) {
        return `Última alteração há ${diferencaDias} dias`;
    } else {
        return `Última alteração em ${convertISOToBr(dataRecebida)}`;
    }
};